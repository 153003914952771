@import '../../assets/scss/variables';

.payment-section{
    width: 90%;
    max-width: 1600px;
    margin: 0 auto;
    .payment-methods{
        background: $white;
        border-radius: 35px;
        box-shadow: 0px 0px 64.298px 0px rgba(52, 52, 52, 0.25);
        padding: 2rem;
        margin-left: -13rem;
        min-height: 600px;
        position: relative;
    }
    .plan-block{
        position: relative;
        z-index: 10;
    }
    .payment-tabs{
        padding-left: 12rem;
        h2{
            margin: 0px;
            color: #0E0020;
            font-size: 36px;
            font-weight: 600;
            letter-spacing: -1.8px;
        }
        .payment-form{
            position: relative;
            padding: 2rem;
        }
    }
}
.p-tabview{
    margin-top: 1.2rem;
    .p-tabview-nav-container{
        .p-tabview-nav-content{
            .p-tabview-nav{
                display: inline-flex;
                padding: 7px;
                border-radius: 8px;
                border: 1px solid #E2E3EE;
                background: transparent;
                width: 100%;
                align-items: center;
                gap: 6px;
                .p-unselectable-text{
                    // width: 100%;
                    min-width: 343px;
                    cursor: pointer;
                    .tab{
                        padding: 13px 18px;
                        font-size: 16px;
                        font-weight: 500;
                        color: $purple-dark;
                        border: none;
                        border-radius: 4px;
                        background: $white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .p-image{
                            line-height: 0px;
                            filter: brightness(100%) contrast(100%);
                        }
                    }
                    &.p-tabview-selected{
                        .tab{
                            background: rgba(151, 71, 255, 0.25);
                        }
                    }
                }
                .p-tabview-ink-bar{
                    display: none;
                }
            }
        }
    }
    .p-tabview-panels{
        padding: 0px;
        background: transparent;
        .card-section{
            margin-top: 2rem;
            h3{
                margin: 0rem 0rem 1.5rem;
                color: $black;
                font-size: 16px;
                font-weight: 600;
                line-height: 160%;
            }
            .p-inputtext{
                border-radius: 8px;
                border: 1px solid #E2E3EE;
                padding: 12px 18px;
                color: $purple-dark;
                font-size: 16px;
                font-weight: 400;
                line-height: 160%;
                width: 100%;
                &.p-inputtextarea{
                    height: 200px;
                    resize: none;
                }
                &.cardInput{
                    padding: 15px 18px;
                }
            }
            p{
                color: $purple-dark;
                font-size: 16px;
                font-weight: 400;
                line-height: 160%;
                letter-spacing: -0.8px;
                margin: 0px;
                &.small_p{
                    font-size: 14px;
                    line-height: 150%;
                    letter-spacing: -0.7px;
                }
                a{
                    text-decoration: underline;
                }
            }
            .card-icon{
                position: absolute;
                right: 15px;
                top: 15px;
            }
        }
    }
}

.payment-success-section{
    .payment-details-block{
        color: #0E0020;
        ul{
            margin: 0px;
            padding: 1.3rem 0rem;
            border-bottom: 1px solid #DFDFDF;
            h3{
                margin: 1.3rem 0px 0px;
                font-size: 24px;
                font-weight: 400;
                line-height: 160%;
            }
            &:first-child{
                padding: 0rem 0rem 1.0938rem;
            }
            &:last-child{
               border-bottom: 0px;
               padding: 1.3rem 0rem 0rem;
            }
            li{
                list-style: none;
                font-size: 20px;
                font-weight: 400;
                line-height: 160%;
                &:last-child{
                    font-weight: 500;
                    text-align: right;
                    span{
                        line-height: 0;
                    }
                }
                .completed{
                    border-radius: 9px;
                    background: #E1F4E2;
                    padding: 10px 15px;
                    color: #0C8F09;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 160%;
                }
                .payment{
                    border-radius: 10px;
                    padding: 8px 16px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 160%;
                    text-align: center;
                    min-width: 120px;
                }
                .payment-{
                    &completed{
                        color: #67b173;
                        background-color: rgba(103, 177, 115, 0.15);
                    }
                    &pending, &purchase_revoked {
                        color: #efb226;
                        background-color: rgba(255, 200, 75, 0.15);
                    }
                    &failed,  &payment_failed, &payment_cancelled, &purchase_failed {
                        color: #f17171;
                        background-color: rgba(241, 113, 113, 0.15);
                    }
                }
                .success{
                    line-height: 0px;
                }
                h2{
                    margin: 0px;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 150%;
                    letter-spacing: 0px;
                }
                p{
                    margin: 0px;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 160%;
                }
                a{
                    color: #0E0020;
                    &:hover{
                        color: $primary;
                    }
                }
                .pi{
                    font-size: 30px;
                    cursor: pointer;
                    &:hover{
                        color: $primary;
                    }
                }
                .view-button {
                    background: $accent;
                    border: none;
                    padding: 12px 20px;
                    width: 100%;
                    text-align: center;
                    height: 64px;
                    border-radius: 12px;
                    .p-button-label {
                        color: $white;
                        font-size: 22px;
                        font-weight: 600;
                        line-height: 160%;
                    }
                    &:hover {
                        background: $primary; 
                    }
                }
                
            }
            &.header-ul{
                padding-bottom: 2rem;
            }
        }
    }
}

.confirm-button{
    all: unset;
    padding: 16px 32px;
    border-radius: 10px;
    background: #EA4335;
    text-align: center;
    .p-button-label{
        font-size: 20px;
        font-weight: 600;
        line-height: 140%;
        letter-spacing: -1px;
        color: $white;
    }
    &:hover{
        background: $accent;
        cursor: pointer;
    }
    &.custom-width{
        width: 100%;
        max-width: 300px;
    }
}
.paypal-section-button{
    width: 100%;
    margin: 0 auto;
    max-width: 750px;
}
.loading-section{
    &.payment-load{
        background: rgba(22, 0, 51, 0.80);
        z-index: 9;
        border-radius: 10px;
    }
}

@media (max-width:962px){
    .payment-section{
        .plan-block{
            padding-top: 2rem;
        }
        .payment-methods{
            border-radius: 5px;
            box-shadow: none;
            padding: 0rem;
            margin-left: 0rem;
        }
        .payment-tabs{
            padding-left: 0rem;
            .payment-form{
                padding: 10px;
            }
            h2{
                font-size: 28px;
            }
        }
        .mobile-full-width{
            width: 100%;
        }
    }
    .p-tabview .p-tabview-nav-container .p-tabview-nav-content .p-tabview-nav .p-unselectable-text{
        min-width: auto;
    }
    .card-section{
        padding-bottom: 2rem;
    }
    .payment-success-section{
        .payment-details-block{
            ul{
                li{
                    font-size: 15px;
                    letter-spacing: -0.7px;
                    h2{
                        font-size: 18px;
                    }
                    .success{
                        img{
                            width: 45px;
                        }
                    }
                    p{
                        font-size: 15px;
                    }
                    .pi {
                        font-size: 20px;
                    }
                }
                h3{
                    font-size: 15px;
                }
            }
        }
    }
}
