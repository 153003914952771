@import '../../assets/scss/variables';


.err{
    &-msg{
        font-size: 12px;
        color: $primary;
        display: block;
    }
}
.flag-dropdown{
    color: $white;
}