@import '../../assets/scss/variables';

.add-device-button {
    border-radius: 10px;
    background: #160033;
    padding: 16px 56px;
    border: none;

    .p-button-label {
        color: $white;
        font-size: 16px;
        font-weight: 400;
    }

    .p-button-icon {
        font-size: 13px;
        margin-right: 10px;
    }

    &:hover {
        background: $primary;
    }
}

.text-gray-grid{
    color:#A4A4A4
}

.dashboard-main {
    width: 100vw;
    height: 100vh;
    background: $white url('../../assets/images/bg-pattern.png') right no-repeat;
    background-size: contain;
    padding: 50px;

    .dashboard-wrapper {
        border-radius: 40px;
        background: #FEFEFE;
        box-shadow: 0px 0px 75px 0px rgba(52, 52, 52, 0.25);
        width: 100%;
        display: flex;
        height: 100%;
        min-height: 675px;

        .sidebar-block {
            background: #160033;
            border-radius: 38px 0px 0px 38px;
            width: 150px;
            padding: 35px;
            min-height: 500px;

            .side-navbar {
                all: unset;
                height: 100%;
                .logo-img{
                    cursor: pointer;
                }
                .p-menubar-start{
                    height: 100%;
                }

                .p-menubar-end {
                    margin-left: 0px;
                }
                .menu-block{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                }

                ul {
                    margin: 4rem 0px 0px;
                    padding: 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 50px;

                    li {
                        list-style: none;
                        display: block;

                        .menu-icon {
                            img {
                                filter: contrast(0%) brightness(2);
                            }
                        }

                        &:hover {
                            cursor: pointer;

                            .menu-icon {
                                img {
                                    filter: brightness(0) saturate(100%) invert(44%) sepia(91%) saturate(4381%) hue-rotate(328deg) brightness(97%) contrast(100%);
                                }
                            }
                        }

                        &.active {
                            .menu-icon {
                                img {
                                    filter: brightness(0) saturate(100%) invert(44%) sepia(91%) saturate(4381%) hue-rotate(328deg) brightness(97%) contrast(100%);
                                }
                            }
                        }
                    }
                }
            }
        }

        .content-block {
            padding: 55px;
            width: 100%;
            border-radius: 0px 38px 38px 0px;
            min-height: 500px;

            .content-header {
                h1 {
                    font-size: 36px;
                    font-weight: 600;
                    letter-spacing: -1.8px;
                    margin: 0px;
                }
                .p-inputtext {
                    border: none;
                    border-bottom: 1px solid #E2E3EE;
                    padding: 6px 10px 6px 35px;
                    color: #160033;
                    font-size: 16px;
                    font-weight: 400;
                    border-radius: 0px;
                    &:focus{
                        box-shadow: none;
                    }
                }
                .search-button {
                    position: relative;
                    left: 20px;
                    color: #160033;
                    font-size: 16px;
                    background: none;
                    z-index: 9;
                    padding: 0px;
                    width: auto;
                    border: none;
                    &:focus{
                        box-shadow: none;
                    }
                }

            }

            .data-table {
                height: calc(100vh - 400px);
                min-height: 450px;

                &::-webkit-scrollbar {
                    width: 7px;
                    border-radius: 6px;
                }

                &::-webkit-scrollbar-track {
                    background: #E2E3EE;
                    border-radius: 6px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #574C65;
                    padding: 2px;
                    border-radius: 6px;
                }

                .no-header .p-datatable-thead {
                    display: none;
                    padding: 0px;
                }
                .devices{
                    width: 30.7%;
                }
                .status{
                    width:12%;
                    text-align: center;
                }
                .plan{
                    width: 12.5%;
                }
                .date{
                    width: 14%;
                }

                .device-icon {
                    width: 62px;
                    height: 62px;
                    border-radius: 50%;
                    background: #FAFAFA;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .p-image {
                        img {
                            width: 100%;
                        }
                    }
                }

                p {
                    color: #160033;
                    font-size: 24px;
                    font-weight: 600;
                    margin: 15px 0px;

                    span {
                        color: #BABABA;
                        font-size: 15px;
                        font-weight: 400;
                        display: block;
                        margin-top: 5px;
                    }
                }

                .p-datatable-thead {
                    background: $white;
                    display: table;
                    width: 100%;

                    tr {
                        th {
                            background: none;
                            border-color: #0E0020;

                            .p-column-title {
                                color: #0E0020;
                                font-size: 18px;
                                font-weight: 600;
                            }

                            &.action-column {
                                .p-column-header-content {
                                    justify-content: center;
                                }
                            }
                        }
                    }
                }

                .p-datatable-tbody {
                    tr {
                        display: table;
                        width: 100%;
                        table-layout: fixed;

                        td {
                            color: #160033;
                            font-size: 17px;
                            font-weight: 500;
                            line-height: 130%;
                            padding: 1rem 8px;
                            .column-name{
                                display: none;
                                color: #BABABA;
                                font-size: 13px;
                                font-weight: 400;
                            }

                            .subscription-button {
                                border-radius: 6px;
                                background: #6A459A;
                                padding: 16px 20px;
                                border: none;
                                width: 100%;
                                max-width: 230px;

                                .p-button-label {
                                    font-size: 16px;
                                    font-weight: 400;
                                }

                                &:hover {
                                    background: $primary;
                                }
                            }

                            .button {
                                background: #EA4335;
                                border: none;
                                padding: 16px 20px;
                                width: 100%;
                                max-width: 52px;

                                &:hover {
                                    background: $black;
                                }

                                &-trash {
                                    @extend .button;
                                }

                                &-download {
                                    @extend .button;
                                    max-width: 45px;
                                    padding: 12px 20px;
                                }
                               &-non-trash{
                                min-width: 45px;
                               }
                            }

                            .success-button {
                                border-radius: 10px;
                                background: #C3E2C4;
                                padding: 7px 16px;
                                border: none;

                                .p-button-label {
                                    font-size: 18px;
                                    font-weight: 400;
                                    color: #0C8F09;
                                    line-height: 160%;
                                }
                            }

                            .success-staus {
                                border-radius: 10px;
                                background: #C3E2C4;
                                padding: 8px 16px;
                                font-size: 16px;
                                font-weight: 400;
                                color: #0C8F09;
                                line-height: 160%;
                                text-align: center;
                                min-width: 120px;
                            }
                            .status-badge {
                                font-size: 20px;
                                font-weight: 500;
                                padding-left: 25px;
                                position: relative;

                                &::before {
                                    content: '';
                                    width: 9px;
                                    height: 9px;
                                    background: $black;
                                    position: absolute;
                                    border-radius: 10px;
                                    left: 0px;
                                    top: 7px;
                                }

                                &.status {
                                    &-trial {
                                        color: #F2C215;

                                        &::before {
                                            background: #F2C215;
                                        }
                                    }

                                    &-active {
                                        color: #37D400;

                                        &::before {
                                            background: #37D400;
                                        }
                                    }

                                    &-inactive {
                                        color: #EA4335;

                                        &::before {
                                            background: #EA4335;
                                        }
                                    }
                                }
                            }

                            &.last-column {
                                .flex {
                                    justify-content: center;
                                }
                            }
                            .date-flex{
                                b{
                                    font-weight: 500;
                                }
                            }
                            .no-data-span{
                                padding-left: 22%;
                            }
                        }
                    }
                }
            }
        }
    }
}

.add-device-dialog {
    .p-dialog {
        width: 90%;
        max-width: 585px;
        box-shadow: none;

        .p-dialog-header {
            border-radius: 28px 28px 0px 0px;
            padding: 48px 48px 0px;

            h2 {
                font-size: 28px;
                font-weight: 600;
                line-height: 150%;
                letter-spacing: -1.4px;
                margin: 0px 0px 1.4rem;
                color: #0E0020;

                span {
                    border-radius: 12px;
                    background: #44345A;
                    width: 38px;
                    height: 38px;
                    font-size: 16px;
                    font-weight: 400;
                    color: $white;
                    display: inline-block;
                    text-align: center;
                    vertical-align: top;
                    margin-right: 10px;

                    &.add-icon {
                        line-height: 35px;
                    }
                }
            }

            .p-dialog-header-icon {
                svg {
                    width: 25px;
                    height: 25px;
                }

                &:hover {
                    background: none;

                    svg {
                        color: $primary;
                    }
                }
            }
        }

        .p-dialog-content {
            padding: 0px 48px 48px;

            &:last-of-type {
                border-radius: 0px 0px 28px 28px;
            }

            .device-section-wrapper {
                padding: 0px;
                box-shadow: none;

                .device-confirm-button {
                    max-width: 100%;
                }
            }

            .cancel-subscription {
                h3 {
                    color: #0E0020;
                    font-size: 28px;
                    font-weight: 500;
                    line-height: 120%;
                    letter-spacing: -1.4px;
                    margin: 0px;
                }

                p {
                    color: #0E0020;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 120%;
                    letter-spacing: -0.7px;
                    margin-bottom: 0px;
                }

                .button-container {
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    align-items: center;
                }

                .device-confirm-button {
                    background: #EA4335;
                    color: $white;
                    max-width: 100%;

                    &:hover {
                        background:$black;
                    }

                    &.cancel-button {
                        background: $white;
                        border: 1px solid #ddd;

                        .p-button-label {
                            color: $black;
                        }

                        &:hover {
                            background: $black;
                            border-color: $black;

                            .p-button-label {
                                color: $white;
                            }
                        }
                    }
                }
            }

            .renew-subscription {
                p {
                    color: #0E0020;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 120%;
                    letter-spacing: -0.9px;
                    margin: 6px 0px;
                }

                .device-confirm-button {
                    max-width: 100%;
                }
            }
        }
    }
}

.error-message {
    color: #EA4335;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.9px;
    text-align: center;
    display: block;
    margin-top: 1.5rem;
}

.settings-section {
    margin-top: 3.75rem;

    .left-block {
        border-right: 1px solid #7C7290;
        padding-right: 6rem;

        .p-inputtext {
            border: 1px solid #E2E3EE;
            padding: 16px 22px;
            font-size: 18px;
            font-weight: 400;
            border-radius: 9px;
            width: 100%;
            color: #0E0020;
        }

        label {
            color: #0E0020;
            font-size: 20px;
            font-weight: 400;
            line-height: 160%;
        }
    }

    .right-block {
        padding-left: 6rem;

        .language-dropdown {
            margin: 1rem 0rem 3rem;
            width: 100%;
            max-width: 85%;

            .p-dropdown-label {
                padding: 16px 22px;
            }
        }

        .useful-links {
            float: inline-start;

            a {
                color: #0E0020;
                font-size: 24px;
                font-weight: 600;
                letter-spacing: -1.2px;
                text-decoration: underline;
                display: block;

                &:hover {
                    color: $primary;
                    text-decoration: none;
                }
            }
        }
    }

    h2 {
        color: #0E0020;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -1.2px;
        margin: 0px;
        &.category-h2{
            margin-bottom: 12px;
            font-size: 20px;
            color: $accent;
        }
    }

    .edit-button {
        all: unset;
        border-radius: 8px;
        border: 1px solid $black;
        padding: 4px 16px 5px;

        .p-button-label {
            color: $black;
            font-size: 12px;
            font-weight: 400;
        }

        .p-button-icon {
            font-size: 12px;
        }

        &:hover {
            background: $accent;
            border: 1px solid $accent;
            cursor: pointer;

            .p-button-label {
                color: $white;
            }

            .p-button-icon {
                color: $white;
            }
        }

        &.active-class {
            background: $accent;
            border: 1px solid $accent;

            .p-button-label {
                color: $white;
            }

            .p-button-icon {
                color: $white;
            }
        }
    }

    .save-button {
        border-radius: 12px;
        background: #F73859;
        padding: 16px 45px;
        border: none;

        .p-button-label {
            font-size: 20px;
            font-weight: 600;
            line-height: 164%;
        }

        &:hover {
            background: $accent;
        }
    }

    .success-msg {
        color: #54A641;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: -0.9px;
    }

    .trash-button {
        all: unset;
        color: #F73859;
        cursor: pointer;
        margin-top: 1.5rem;

        &:hover {
            color: $accent;
        }
    }
}

.password-button {
    all: unset;
    position: absolute;
    top: 54px;
    right: 25px;

    .p-button-label {
        color: #0E0020;
        font-size: 18px;
        font-weight: 400;
        line-height: 160%;
        text-decoration-line: underline;
    }

    &:hover {
        cursor: pointer;

        .p-button-label {
            color: $primary;
        }
    }
}

.slider-container {
    display: flex;
    margin: 2rem auto 0px;
    overflow: hidden;
    align-items: center;
}

.content-side {
    flex: 1;
    padding: 20px;

    h2 {
        color: #160033;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: 106%;
        letter-spacing: -3.2px;
        margin: 1.5rem 0px;
    }

    h4 {
        color: #160033;
        font-size: 20px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -1px;
        margin: 2px 0px;
    }

    p {
        @extend h4;
        font-weight: 500;
    }

    .slider-buttons {
        border-radius: 14px;

        .p-button-label {
            font-size: 25px;
            font-weight: 600;
            line-height: 157.72%;
            letter-spacing: -1.253px;
        }
    }
}

.image-side {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .status-span {
        border-radius: 58px;
        border: 1px solid $border-color;
        background: $black-30;
        padding: 12px 35px;
        position: absolute;
        top: 3rem;
        right: 6rem;
        font-size: 13px;
        font-weight: 400;
        color: $border-color;

        &::before {
            content: "";
            width: 5px;
            height: 5px;
            display: inline-block;
            background: $border-color;
            border-radius: 5px;
            position: relative;
            top: -1px;
            left: -7px;
        }

        &.active {
            border: 1px solid #298921;
            background: #00FF19;
            color: #165C29;

            &::before {
                background: #165C29;
            }
        }

        &.offline {
            border: 1px solid #892121;
            background: #F26A6A;
            color: #710909;

            &::before {
                background: #710909;
            }
        }
    }
}

.image-side img {
    width: 100%;
    height: 100%;
    min-height: 650px;
    max-width: 650px;
    border-radius: 100%;
    object-fit: cover;
}

.arrows {
    position: absolute;
    bottom: 0px;
    right: 10px;
    display: flex;
    gap: 10px;
}

.arrows button {
    all: unset;
    color: #0E0020;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background: $white;
    border: 3px solid;
    text-align: center;

    .pi {
        font-size: 22px;
    }
}

.arrows button:hover {
    border: 3px solid $primary;

    .pi {
        color: $primary;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.table-loading {
    color: $accent;
}

.loading-section {
    width: 100%;
    height: 100%;
    background: rgba(22, 0, 51, 0.90);
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;

    .pi-spin {
        font-size: 20px;
    }

    span {
        font-size: 20px;
    }
}

.p-datatable .p-datatable-loading-overlay {
    display: none;
}

.device-section-wrapper {
    width: 100%;
    max-width: 820px;
    margin: 0 auto;
    border-radius: 35px;
    background: $white;
    box-shadow: 0px 0px 64.298px 0px rgba(52, 52, 52, 0.25);
    padding: 70px;

    h2 {
        font-size: 28px;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -1.4px;
        margin: 0px 0px 1.4rem;
        color: #0E0020;

        span {
            border-radius: 12px;
            background: #44345A;
            width: 38px;
            height: 38px;
            font-size: 16px;
            font-weight: 400;
            color: $white;
            display: inline-block;
            text-align: center;
            vertical-align: top;

            &.add-icon {
                line-height: 36px;
            }
        }
    }

    .p-dropdown {
        border: 1px solid #E2E3EE;

        &.p-focus {
            box-shadow: none;
        }
    }

    .p-inputtext {
        border-radius: 8px;
        border: 1px solid #E2E3EE;
        padding: 12px 18px;
        color: $purple-dark;
        font-size: 16px;
        font-weight: 400;
        line-height: 160%;

        &.p-dropdown-label {
            border: none;
        }
        &.p-placeholder{
            color: #818181;
        }
    }

    .p-password {
        .p-icon-field {
            width: 100%;

            .p-inputtext {
                width: 100%;
            }

            .p-input-icon {
                &:focus-visible {
                    outline: none;
                }
            }
        }
    }
}

.device-confirm-button {
    padding: 14px 27px;
    border-radius: 10px;
    background: #6A459A;
    text-align: center;
    width: 100%;
    max-width: 325px;
    margin-top: 1.6rem;
    border: none;
    &.w-full{
        max-width: 100%;
    }
    .p-button-label {
        font-size: 20px;
        font-weight: 600;
        line-height: 160%;
        letter-spacing: -1px;
        color: $white;
    }

    &:hover {
        background: $primary;
        cursor: pointer;
    }
}

// Responsive CSS

@media (max-width:768px) {
    .settings-section {
        .right-block {
            padding-left: 0rem;
            margin-top: 2rem;
        }
    }

    .add-device-dialog .p-dialog {
        .p-dialog-header {
            padding: 20px 20px 0px;
        }

        .p-dialog-content {
            padding: 16px 20px 20px;
        }
    }
}

@media (min-width:1200px) and (max-width:1600px) {
    .dashboard-main {
        padding: 25px;

        .dashboard-wrapper{
            .sidebar-block{
                padding: 30px;
                width: 125px;
                .side-navbar .logo-img{
                    img{
                        width: 65px;
                        margin: 0 auto;
                    }
                }
            }
            .content-block {
                padding: 30px;
    
                .data-table {
                    min-height: 450px;
                    .p-datatable-tbody tr td {
                        font-size: 15px;
                        word-wrap: break-word;
    
                        .status-badge {
                            font-size: 16px;
                            padding-left: 15px;
                        }
                        .success-button{
                            min-width: 105px;
                            .p-button-label{
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
    }

    .settings-section {
        .left-block {
            padding-right: 4rem;
        }

        .right-block {
            padding-left: 4rem;
        }
    }
    .header-hide{
        font-size: 13px;
    }
}

.p-tooltip {
    .p-tooltip-text{
        background-color: #501D95;
        color: $white;
        font-size: 15px;
    }
    .p-tooltip-arrow {
        border-right-color: #501D95;
    }
    
}

.empty-secton{
    width: 100%;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
        color: $black;
        font-size: 24px;
        font-weight: 400;
        line-height: 125%;
        letter-spacing: -1.2px;
    }
    .add-button{
        @extend .add-device-button;
        margin-top: 1.2rem;
    }
    &.faq-empty-secton{
        height: 60vh;
    }
}
.content-section{
    margin-top: 2rem;
    height: calc(100vh - 330px);
    padding-right: 3rem;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #ebebeb;
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background: #574C65;
        padding: 2px;
        border-radius: 8px;
    }
    h2{
        color: #0E0020;
        font-size: 22px;
        font-weight: 600;
        letter-spacing: -1.2px;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }
    p{
        color: $black;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -1px;
        a{
            text-decoration: underline;
            color: $primary;
        }
        &.sub-heading{
            font-size: 18px;
            font-weight: 600;
        }
    }
    ul{
        margin: 1rem 0px;
        li{
            color: $black;
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -1px;
            margin-bottom: 15px;
            a{
                text-decoration: underline;
                color: $primary;
            }
        }
    }
}

.faq-section{
    height: calc(100vh - 360px);
    overflow-x: hidden;
    min-height: 500px;
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #ebebeb;
        border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background: #574C65;
        padding: 2px;
        border-radius: 8px;
    }
    .left-block{
        padding-right: 3rem;
        .faq-category{
            padding-bottom: 1.2rem;
        }
    }
    .right-block{
        padding-left: 3rem;
        padding-right: 10px;
        .faq-category{
            padding-bottom: 1.2rem;
        }
    }
}

.p-accordion-header-text{
    font-size: 18px;
    font-weight: 600;
    padding-right: 3rem;
    line-height: 140%;
    letter-spacing: -1px;
}
.p-accordion{
    .p-accordion-tab{
        margin: 0rem 0rem 1.5rem;
        .p-accordion-header .p-accordion-header-link{
            background-color: $white;
            border: 1px solid #E2E3EE;
            color: $black;
            overflow: hidden;
            border-radius: 15px;
            padding: 0px 20px 0px 0px;
            .p-accordion-toggle-icon{
                display: none;
            }
            .p-accordion-header-text{
                padding-right: 0rem;
                .question{
                    padding: 20px 0px 20px 20px;
                    word-break: break-word;
                }
            }
            &::after{
                content: "\e90d";
                font-family: primeicons;
                font-size: 20px;
            }
        }
        &.p-accordion-tab-active{
            .p-accordion-header .p-accordion-header-link{
                &::after{
                    content: "\e90b";
                }
            }
        }
    }
    .p-accordion-content{
        font-size: 17px;
        line-height: 30px;
        border-radius: 0px 0px 15px 15px;
        border: 1px solid #E2E3EE;
        background: #fff;
        color: #241B30;
        padding: 20px 20px;
        margin-top: -1px;
        p{
            &:first-child{
                margin-top: 0;
            }
            span{
                font-weight: 600;
            }
            a{
                color: $accent;
                &:hover{
                    color: $primary;
                    text-decoration: underline;
                }
            }
        }
    }
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link{
    background: #fbfbfb;
    color: $black;
    border-radius: 15px 15px 0px 0px;
}
.backto-button{
    display: inline-flex;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 0px;
    font-size: 15px;
    .pi{
        margin-right: 5px;
        font-size: 14px;
    }
    &:hover{
        color: $primary;
        cursor: pointer;
    }
}
.p-datatable * {
    user-select: text;
    -webkit-user-select: text;
    -webkit-user-drag: none;
    -moz-user-select: text;
    -moz-user-drag: none;
}

.tag-edit{
    margin-bottom: 5px;
    display: inline-block;
    .custom-view-wrapper{
        display: flex;
        align-items: center;
        .custom-view-class{
            border-bottom: 2px solid $white;
            line-height: 28px;
            font-weight: 600;
            font-size: 20px;
        }
        input{
            border-radius: 5px;
            border: 1px solid #E2E3EE;
            background: $white;
            padding: 6px 15px;
            color: $black;
            font-family: $font-base;
            font-size: 14px;
            font-weight: 500;
            line-height: 160%;
        }
    }
    button{
        background: none;
        border: none;
        min-width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        cursor: pointer;
        margin-left: 0px;
        &.custom-edit-button{
            position: relative;
            top: 1px;
            left: 8px;
            &::before{
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                background: url('../../assets/images/edit-icon.png') no-repeat;
            }
        }
        &.custom-save-button{
            margin-right: 5px;
            &::before{
                content: "\e909";
                font-family: primeicons;
                color: #31A339;
                background: #f3f3f3;
                border: 1px solid #E2E3EE;
                width: 33px;
                height: 33px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                border-radius: 5px;
            }
            &:hover{
                &::before{
                    background: #31A339;
                    border: 1px solid #31A339;
                    color: $white;
                }
            }
        }
        &.custom-cancel-button{
            margin-right: 8px;
            &::before{
                content: "\e90b";
                font-family: primeicons;
                color: #EA4335;
                background: #f3f3f3;
                border: 1px solid #E2E3EE;
                width: 33px;
                height: 33px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                border-radius: 5px;
            }
            &:hover{
                &::before{
                    background: #EA4335;
                    border: 1px solid #EA4335;
                    color: $white;
                }
            }
        }
    }
}
.dashboard-footer{
    text-align: center;
    p{
        margin: 1rem 0rem;
        font-size: 13px;
        letter-spacing: -0.6px;
        a{
            text-decoration: underline;
            &:hover{
                text-decoration: none;
                color: $primary;
            }
        }
    }
}
.trash-button-tooltip{
    width: 205px;
    .p-tooltip-arrow{
        border-right-color:transparent;
        border-bottom-color: $purple-dark;
    }
    .p-tooltip-text {
        background-color:$purple-dark;
        color: $white;
        font-size: 12px;
        text-align: center;
        padding: 8px;
    }
}

.menu-bar-icon{
    all: unset;
    padding: 0px 5px;
    .pi{
        color: $white;
        font-size: 24px;
    }
}
.menu-label{
    color: $white;
    font-size: 18px;
    display:none;
}

.ellipsis-button{
    cursor: pointer;
    background: none;
    border: none;
    .p-button-icon{
        font-size: 20px;
        color: $black;
        &:hover{
            color: $primary;
        }
    }
    &:focus{
        box-shadow: none;
    }
}
.p-overlaypanel-content{
    .button-trash{
        margin: 10px 0px;
        background: none;
        padding: 0px;
        border: none;
        .p-button-label{
            font-size: 14px;
            font-weight: 400;
            color: $black;
        }
        &:first-child{
            margin-top: 0px;
        }
        &:last-child{
            margin-bottom: 0px;
        }
        &:hover{
            cursor: pointer;
            .p-button-label{
                color: $primary;
            }
        }
        &.p-disabled{
            &:hover{
                cursor: default;
                .p-button-label{
                    color: $black;
                }
            }
        }
        &:focus{
            box-shadow: none;
        }
    }
    .divider{
        width: 100%;
        border-bottom: 1px solid #ddd;
        border-top: none;
    }
} 

.payment-card-section{
    p{
        margin: 0px;
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.8px;
    }
    .payment-label{
        .card-input{
            border-radius: 8px;
            border: 1px solid #E2E3EE;
            background: #FFF;
            padding: 12px 18px 12px 16px;
            &.card-month{
                padding: 1px 16px;
            }
        }
        .label{
            color: #0E0020;
            font-size: 16px;
            font-weight: 600;
            line-height: 120%;
            letter-spacing: -0.8px;
            margin-bottom: 10px;
            margin-top: 1rem;
        }
    }
}

.payment{
    border-radius: 10px;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    text-align: center;
    min-width: 120px;
}
.payment-{
    &completed{
        color: #67b173;
        background-color: rgba(103, 177, 115, 0.15);
    }
    &pending, &purchase_revoked {
        color: #efb226;
        background-color: rgba(255, 200, 75, 0.15);
    }
    &failed,  &payment_failed, &payment_cancelled, &purchase_failed {
        color: #f17171;
        background-color: rgba(241, 113, 113, 0.15);
    }
}
.search{
    display: flex;
    align-items: center;
    .p-inputtext {
        border: none;
        border-bottom: 1px solid #E2E3EE;
        padding: 6px 10px 6px 35px;
        color: #160033;
        font-size: 16px;
        font-weight: 400;
        border-radius: 0px;
        &:focus{
            box-shadow: none;
        }
    }
    .search-button {
        position: relative;
        left: 20px;
        color: #160033;
        font-size: 16px;
        background: none;
        z-index: 9;
        padding: 0px;
        width: auto;
        border: none;
        &:focus{
            box-shadow: none;
        }
    }
}
.date-break{
    span{
        display: none;
    }
}

// Responsive CSS

@media (max-width:962px){
    .mobile-nav{
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2{
            margin: 0px;
            color: $white;
            font-size: 20px;
        }
    }
    .mobile-hide{
        display: none;
    }
    .dashboard-main{
        padding: 15px;
        height: 100%;
        .dashboard-wrapper{
            flex-direction: column;
            position: relative;
            border-radius: 20px;
            .sidebar-block{
                width: 100%;
                border-radius: 15px 15px 0px 0px;
                min-height: auto;
                padding: 20px;
                .side-navbar{
                    position: absolute;
                    background: #160033;
                    left: 0px;
                    width: 100%;
                    height: 98%;
                    z-index: 99;
                    margin: 0 auto;
                    right: 0px;
                    top: 6rem;
                    border-radius: 0px 0px 15px 15px;
                    .p-menubar-start{
                        padding: 0px 2rem;
                    }
                    ul{
                        align-items: flex-start;
                        margin: 2rem 0px 0px;
                        gap: 35px;
                        li{
                            gap: 15px;
                            display: flex;
                            align-items: center;
                            a{
                                gap: 15px;
                                display: flex;
                                align-items: center;
                            }
                            .menu-label{
                                display: block;
                            }
                            &.active{
                               .menu-label{
                                color: $primary;
                               }
                               a{
                                .menu-label{
                                    color: $primary;
                                } 
                               }
                            }
                            .menu-icon img{
                                height: 28px;
                            }
                        }
                    }
                    .menu-block{
                        justify-content: start;
                        align-items: flex-start;
                    }
                }
                .logo-img {
                    img {
                        width: 50px;
                    }
                }
            }
            .content-block{
                padding:12px;
                border-radius: 0px 15px 15px 0px;
                .add-device{
                    width: 100%;
                    .content-header{
                        flex-direction: column;
                        align-items: flex-start !important;
                        padding: 0px 8px;
                        h1{
                            font-size: 26px;
                        }
                        .right-block{
                            margin-top: 2rem;
                            justify-content: space-between;
                            width: 100%;
                            .add-device-button{
                                all: unset;
                                background: #160033;
                                padding: 8px 15px;
                                border-radius: 5px;
                                .p-button-label{
                                    display: none;
                                }
                                .p-button-icon{
                                    margin-right: 0px;
                                    color: $white;
                                }
                            }
                            .p-inputgroup{
                                margin-left: -15px;
                            }
                        }
                        .search{
                            margin-top: 1.5rem;
                        }
                    }
                    .header-hide{
                        display: none !important;
                    }
                }
                .data-table{
                    border-top: none !important;
                    margin-top: 1.5rem;
                    .p-datatable-tbody{
                        tr{
                            display: flex;
                            flex-direction: column;
                            border-radius: 18px;
                            background: #FFF;
                            border: 1px solid rgba(170, 170, 170, 0.25);
                            padding: 10px;
                            margin-bottom: 1.5rem;
                            position: relative;
                            td{
                                width: 100%;
                                font-size: 15px;
                                border-bottom: 1px solid #ededed;
                                padding: 0.5rem 8px;
                                &.last-column{
                                    border-bottom: none;
                                    padding-top: 1rem;
                                    .flex{
                                        justify-content: space-between;
                                        width: 100%;
                                    }
                                }
                                .column-name{
                                    display: block;
                                }
                                .status-badge{
                                    font-size: 16px;
                                    position: absolute;
                                    right: 0px;
                                    &::before{
                                        left: 5px;
                                        top: 5px;
                                    }
                                }
                                &.text-center{
                                    text-align: left !important;
                                }
                                .subscription-button{
                                    padding: 15px 15px;
                                    .p-button-label{
                                        font-size: 16px;
                                    }
                                }
                                .m-flex{
                                    flex-direction: column;
                                    align-items: flex-start !important;
                                    .text-center{
                                        text-align: left !important;
                                    }
                                }
                                .no-data-span{
                                    padding-left: 0%;
                                }
                                .gap-3{
                                    gap: 0.6rem !important;
                                }
                            }
                        }
                    }
                    p{
                        margin: 8px 0px;
                        font-size: 17px;
                    }
                    .devices{
                        margin-top: 1.2rem;
                    }
                    .status{
                        position: absolute;
                        right: 15px;
                        text-align: right;
                        border-bottom: none !important;
                        top: 5px;
                    }
                    &::-webkit-scrollbar {
                        width: 0px;
                    }
                    &::-webkit-scrollbar-thumb {
                        padding:0px;
                    }
                }
            }
        }
    }
    .dashboard-footer {
        padding: 1rem 0rem 0.5rem;
    }
    .faq-section{
        .left-block{
            padding-right: 1rem;
        }
        .right-block{
            padding-left: 0rem;
        }
    }
    .p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link{
        padding: 0px 16px 0px 0px;
        border-radius: 10px;
        .p-accordion-header-text{
            .question{
                font-size: 17px;
                letter-spacing: -1px;
                padding: 12px 0px 12px 12px;
            }
        }
    }
    .settings-section{
        margin-top: 2rem;
        h2{
            font-size: 18px;
        }
        .left-block{
            padding-right: 0rem;
            padding-bottom: 2rem;
            border-right:none;
            border-bottom: 1px solid #7C7290;
            width: 100%;
            label{
                font-size: 16px;
            }
            .p-inputtext{
                padding: 16px 15px;
            }
        }
       .right-block{
        width: 100%;
        .useful-links{
            gap: 1rem !important;
                a{
                    font-size: 16px;
                }   
           }
          .language-dropdown{
            margin: 1rem 0rem 1.5rem;
          }
       }
       .save-button .p-button-label{
            font-size: 16px;
       }
       &.faq-section{
            .left-block{
                padding-bottom: 0rem;
            }
       }
    }
    .password-button .p-button-label{
        font-size: 14px;
    }
    .device-confirm-button .p-button-label{
        font-size: 16px;
        letter-spacing: -0.1px;
    }
    .add-device-dialog .p-dialog{
        .p-dialog-header h2{
            font-size: 20px;
            line-height: 190%;
        }
        .p-dialog-content .cancel-subscription{
            h3{
                font-size: 20px;
            }
        }
    }
    .p-accordion .p-accordion-content{
        font-size: 16px;
    }
    .date-break{
        br{
            display: none;
        }
        span{
            display: inline-block;
        }
    }
    .password-button{
        top: 50px;
        right: 15px;
    }
}

@media (min-width:768px) and (max-width:963px){
    .dashboard-main .dashboard-wrapper .content-block .data-table{
        .p-datatable-tbody{
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            tr{
                flex: 1 1 45%;
            }
        }
    }
    .settings-section {
        .left-block{
            border-bottom:none;
        }
        .right-block{
            padding-left: 0rem;
        }
        &.faq-section{
            .left-block, .right-block{
                padding-bottom: 0rem;
                padding-right: 1rem;
            }
        }
    }
    .add-device-dialog .p-dialog {
        .p-dialog-content {
            padding: 16px 20px 20px;
        }
        .p-dialog-header {
            padding: 20px 20px 0px;
        }
    }
} 
@media (min-width:964px) and (max-width:1199px){
    .dashboard-main{
        padding: 15px;
        .dashboard-wrapper{
            .sidebar-block{
                width: 100px;
                padding: 25px;
                .side-navbar .logo-img{
                    img{
                        width: 65px;
                        margin: 0 auto;
                    }
                }
            }
            .content-block{
                padding: 18px;
                .content-header h1{
                    font-size: 28px;
                }
            }
        }
    }
    .p-accordion-header-text{
        font-size: 17px;
        padding-right: 1rem;
        letter-spacing: -1px;
    }
    .faq-section{
        .right-block{
            padding-left: 2rem;
        }
        .left-block{
            padding-right: 2rem;
        }
    }
    .settings-section{
        .left-block {
            padding-right: 2rem;
        }
        .right-block {
            padding-left: 2rem;
        }
    }
    .header-hide{
        font-size: 14px;
    }
    .dashboard-main .dashboard-wrapper .content-block .data-table .p-datatable-tbody tr td{
        word-wrap: break-word;
        font-size: 14px;
        padding: 0.6rem 5px;
        &.last-column .flex{
            gap:5px !important;
        }
        .button-download{
            max-width: 30px;
            padding: 9px 15px;
        }
        .button-non-trash{
            min-width: 30px;
        }
        .status-badge{
            padding-left: 14px;
            font-size: 14px;
        }
        .subscription-button{
            padding: 16px 10px;
            .p-button-label{
                font-size: 15px;
            }
        }
    }
    .payment{
        border-radius: 5px;
        padding: 8px 8px;
        font-size: 13px;
        min-width: 95px;
    }
    .payment-section{
        .mobile-full-width {
            width: 100%;
        }
        .payment-tabs{
            padding-left: 0rem;
        }
        .payment-methods{
            margin-left: 0rem;
            margin-top: 1.5rem;
            padding: 1rem;
        }
    }
    .add-device-dialog .p-dialog {
        .p-dialog-content {
            padding: 16px 20px 20px;
        }
        .p-dialog-header {
            padding: 20px 20px 0px;
        }
    }
    .password-button {
        right: 15px;
        .p-button-label{
            font-size: 16px;
        }
    }
    
}

@media (max-width:420px){
    .dashboard-main{
        padding: 10px;
        .dashboard-wrapper .content-block .data-table{
            p span{
                font-size: 13px;
            }
            .p-datatable-tbody tr{
                padding: 2px;
            }
            .device-icon{
                width: 55px;
                height: 55px;
            }
        }
    }
}

.ban-mouse {
    cursor: not-allowed !important;
    pointer-events: inherit;
}