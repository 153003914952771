@import url('https://fonts.bunny.net/css2?family=Inter:wght@100..900&display=swap');
@import '../scss/variables';

// **** Prime React CSS **** //
@import 'primereact/resources/themes/lara-light-indigo/theme.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeicons/primeicons.css';
@import 'primeflex/primeflex.scss';

// **** Global CSS **** //
body {
    margin: 0px;
    padding: 0px;
    font-family: $font-base;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        background: #ebebeb;
    }
    &::-webkit-scrollbar-thumb {
        background: #574C65;
        padding: 2px;
    }
}
a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}
.p-component{
    font-family: $font-base;
}
.header-section{
    padding: 20px 50px;
    .main-header{
        all: unset;
        display: flex;
        align-items: center;
        .logo-img{
            all: unset;
        }
        .language-dropdown{
            background: transparent;
            border: none;
            .p-dropdown-label{
                padding: 0px;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: -0.9px;
                color: $secondary-bg;
            }
            &.p-focus{
                box-shadow: none;
                border: none;
            }
            .p-dropdown-trigger{
                color: $secondary-bg;
                width: 14px;
                margin-left: 10px;
            }
        }
        .login-button{
            all: unset;
            border-radius: 8px;
            background: $accent;
            padding: 20px 45px;
            cursor: pointer;
            .p-button-label{
                color: $white;
                font-size: 18px;
                font-weight: 400;
            }
            &:hover{
                background: $primary;
            }
        }
    }
}
.p-dropdown-panel{
    .p-dropdown-items{
        padding: 0px;
        .p-dropdown-item.p-highlight{
            color: #694498;
            background: #f7f1ff;
        }
        .p-dropdown-item:not(.p-highlight):not(.p-disabled).p-focus{
            color: #694498;
            background: #f7f1ff;
        }
    }
}

.language-panel{
    margin-top: 1rem;
    border-radius: 15px;
    border: 1px solid rgba(211, 221, 233, 0.18);
    background: #332447;
    .p-dropdown-items-wrapper{
        border-radius: 10px;
        max-height: 295px !important;
        .p-dropdown-items{
            margin: 0px;
            padding: 8px 15px;
            .p-dropdown-item{
                background: #332447;
                border: 1px solid #332447;
                font-size: 15px;
                padding: 0.5rem 2rem;
                color: $white;
                font-size: 18px;
                font-weight: 500;
                line-height: 160%;
                margin: 4px 0px;
                &.p-highlight{
                    border-radius: 8px;
                    border: 1px solid #504361;
                    background: #3E2E54;
                }
                &:not(.p-highlight):not(.p-disabled).p-focus{
                    background: #3E2E54;
                    border: 1px solid #504361;
                    color: $white;
                    border-radius: 8px;
                }
                &:hover{
                    border-radius: 8px;
                    border: 1px solid #504361;
                    background: #3E2E54;
                }
                
            }
        }
        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 6px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #504361;
            padding: 2px;
            border-radius: 6px;
        }
    }
    &.language-panel{
        margin-left: -5rem;
        &.authenticated{
            margin-left: 0rem;  
        }
    }
}
footer{
    width: 90%;
    margin: 0 auto;
    padding: 0.5rem 0rem 0rem;
    p{
        margin: 0px;
        color: #160033;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.8px;
        .pi{
            margin-left: 10px;
        }
        a{
            text-decoration: underline;
        }
    }
    a{
        color: #160033;
        font-size: 16px;
        font-weight: 500;
        &:hover{
            color: $primary;
        }
    }
}

.full-window-loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(14, 0, 32, 0.25);
    z-index: 9999;
    left: 0px;
    p{
        margin: 0px;
        color: $white;
    }
    .pi-spinner{
        font-size: 20px;
        color: $white;
        margin-right: 10px;
    }
    &.sign-in-loading{
        background: rgba(14, 0, 32, 0.6);
    }
}
.p-menubar-button{
    display: none;
}
.logo-mobile{
    display: none;
}
.support-badge{
    background: $secondary-bg;
    border-radius:0px 6px 6px 0px;
    display: inline-block;
    color: $white;
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg); /* Rotates the badge */
    z-index: 2;
    writing-mode: vertical-lr;
    padding: 0.8rem 0.6rem;

    .image-support {
        transform: rotate(90deg); /* Rotates the image by 90 degrees */
    }
    a{
        color: $white;
    }
    .label{
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: -0.6px;
    }
    .p-image{
        line-height: 0px;
        img{
            width: 20px;
        }
    }
    &:hover{
        background: $primary;
        a{
            color: $white;
        }
    }
}
.p-toast{
    bottom: 10px;
    top: auto !important;
    z-index: 99999;
    width: 90%;
    .p-toast-message{
        border: none;
        color: $white;
        .p-toast-message-content{
            align-items: center;
            padding: 8px 10px;
            .p-icon{
                width: 20px;
                height: 20px;
                color: $white;
            }
            .p-toast-message-text{
                margin: 0 0 0 10px;
                .p-toast-summary{
                    display: none;
                }
                .p-toast-detail{
                    margin: 0rem 0 0 0;
                    font-size: 14px;
                }
            }
        }
        .p-toast-icon-close{
            width: 25px;
            height: 25px;
            .p-icon{
                width: 18px;
                height:18px;
                padding: 3px;
                border: none !important;
            }
            &:hover{
                background: $white;
                .p-icon{
                    color: $black;
                }
            }
        }
        &.p-toast-message-error{
            background: #ff5757;
        }
        &.p-toast-message-success{
            background: #039855;
            .p-toast-message-content{
                .p-icon{
                    border: 1px solid;
                    border-radius: 10px;
                    padding: 4px;
                }
            }
        }
        &.p-toast-message-warn{
            background: #ea580c;
            .p-toast-message-content{
                .p-icon{
                    width: 18px;
                    height: 18px;
                }
            }
        }
        &.p-toast-message-info{
            background: #3b82f6;
        }
    }
}

.content-section{
    word-wrap: break-word;
    ol{
        li[data-list="bullet"]{
            list-style: disc;
        }
        li{
            padding: 4px 0px;
        }
    }
    p{
        br{
            display: none;
        }
    }
    .ql-indent-1 {
        padding-left: 30px;
    }
    .ql-indent-2 {
        padding-left: 60px;
    }
    .ql-indent-3 {
        padding-left: 90px;
    }
    table {
        border: 1px solid #332447;
        border-radius: 14px;
        padding: 0;
        width: 100%;
        td{
            padding: 1rem;
            border-bottom: 1px solid #eaecf01a;
        }
        tr{
            &:first-child td {
                background-color: #eee;
            }
        }
      }
}
.p-accordion .p-accordion-content{
    ol{
        li[data-list="bullet"]{
            list-style: disc;
        }
    }
    p{
        br{
            display: none;
        }
    }
    .ql-indent-1 {
        padding-left: 30px;
    }
    .ql-indent-2 {
        padding-left: 60px;
    }
    .ql-indent-3 {
        padding-left: 90px;
    }
}

// Responsive CSS

@media (max-width:962px){
    .header-section {
        padding: 20px;
        .logo-img{
            display: block;
            line-height: 0px;
            img{
                width: 100%;
                max-width: 125px;
            }
        }
        .logo-mobile{
            img{
                width: 50px;
            }
        }
        .p-menubar-end{
            .gap-5{
                gap: 0.8rem !important;
            }
        }
        .main-header .login-button{
            padding: 15px 25px;
        }
    }
    .language-panel.language-panel{
        margin-left: 0rem;
    }
}
@media (min-width:1200px) and (max-width:1600px){
    .header-section {
        padding: 20px 50px;
    }
    footer {
        padding: 2rem 0rem 0rem;
    }
}

@media (min-width:963px) and (max-width:1199px){
    .header-section {
        padding: 20px 50px;
    }
    footer {
        padding: 1rem 0rem 3rem;
    }
}

@media (max-width:767px){
    .header-section{
        padding: 15px;
        .main-header{
            .login-button{
                padding: 10px 15px;
                .p-button-label{
                    font-size: 14px;
                }
            }
            .language-dropdown .p-dropdown-label{
                font-size: 16px;
            }
        }
    }
    .content-section{
        .ql-indent-1 {
            padding-left: 20px;
        }
        .ql-indent-2 {
            padding-left: 40px;
        }
        .ql-indent-3 {
            padding-left: 60px;
        }
    }
    .p-accordion .p-accordion-content{
        .ql-indent-1 {
            padding-left: 20px;
        }
        .ql-indent-2 {
            padding-left: 40px;
        }
        .ql-indent-3 {
            padding-left: 60px;
        } 
    }
}

@media (max-width:460px){
    .header-section .main-header{
        .logo-img{
            display: none;
        }
        .logo-mobile{
            display: block;
        }
    }
}