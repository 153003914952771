
$font-base: "Inter", sans-serif;
// Colors
$primary: #F73859;
$accent: #501D95;
$black: #000000;
$white: #ffffff;
$black-30:#B8B8BC;
$secondary-bg: #170134;
$btn-bg:#6B4C93;
$icon-bg:#301E4C;
$border-color:#4E4E4E;
$purple-dark:#160033;
$blue-bg:#1D0041;