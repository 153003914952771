@import '../../assets/scss/variables';

.terms-and-conditions-section{
    width: 90%;
    max-width: 1300px;
    margin: 0 auto;
    padding: 2rem 0rem;
    min-height: calc(100vh - 260px);
    .backto-button{
        display: none;
    }
    .content-header{
        justify-content: center !important;
    }
    .content-section{
        height: auto;
        padding-right: 0rem;
    }
    h1{
        color: #160033;
        font-size: 60px;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -3.2px;
        margin: 0px;
        text-align: center;
    }
}
.footer-block{
    padding: 2rem 0rem;
}
.faq-page-section{
    width: 90%;
    max-width: 1340px;
    margin: 0 auto;
    padding: 2rem 0rem;
    h1{
        color: #160033;
        font-size: 60px;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -3.2px;
        margin: 0px;
        text-align: center;
    }
    .faq-section{
        height:auto !important;
        .p-accordion-header-text{
            font-size: 18px;
            padding-right: 2rem;
            letter-spacing: -1px;
        }
        .p-accordion .p-accordion-content p{
            font-size: 16px;
            span{
                font-weight: 600;
            }
        }
    }
    .faq-empty-section{
        min-height: 450px;
    }
}

@media (max-width:767px){
    .faq-page-section{
        h1{
            font-size: 28px;
            letter-spacing: -2px;
        }
        .content-header{
            justify-content: center !important;
            flex-direction: column;
            .search{
                margin-top: 1.5rem;
            }
        }
    }
    .terms-and-conditions-section h1{
        font-size: 28px;
        letter-spacing: -2px;
    }
    .empty-section {
        .faq-category{
            .p-skeleton{
                height: 40px !important;
                &:first-child{
                    height: 20px !important;
                }
            }
        }
    }
}
@media (min-width:768px) and (max-width:963px){
    .faq-page-section{
        h1{
            font-size: 36px;
            letter-spacing: -2px;
        }
        .content-header{
            justify-content: center !important;
            flex-direction: column;
            .search{
                margin-top: 1.5rem;
            }
        }
    }
    .terms-and-conditions-section h1{
        font-size: 36px;
        letter-spacing: -2px;
    }
}
@media (min-width:964px) and (max-width:1199px){
    .faq-page-section{
        h1{
            font-size: 38px;
            letter-spacing: -2px;
        }
    }
    .terms-and-conditions-section h1{
        font-size: 38px;
        letter-spacing: -2px;
    }
}