@import '../../../assets/scss/variables';


.button{
    all: unset;
    border-radius: 8px;
    background: $primary;
    padding: 15px 45px;
    cursor: pointer;
    text-align: center;
    .p-button-label{
        color: $white;
        font-size: 18px;
        font-weight: 400;
    }
    &:hover{
        background: $accent;
    }
}

.auth-wrapper{
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    background: $white url('../../../assets/images/bg-pattern.png') 85% no-repeat;
    background-size: contain;
    .form-wrapper{
        width: 90%;
        margin: 0 auto;
        display: flex;
        height: calc(100vh - 195px);
        min-height: 665px;
        .left-block{
            h1{
                color: $secondary-bg;
                font-size: 64px;
                font-weight: 600;
                line-height: 106%;
                letter-spacing: -3.2px;
                margin: 0px 0px;
            }
            h2{
                color: $secondary-bg;
                font-size: 40px;
                font-weight: 400;
                line-height: 106%;
                letter-spacing: -3.2px;
                margin: 25px 0px 0px;
            }
            .invalid-class{
                &.p-inputtext{
                    border: 2px solid $primary;
                }
            }
            .p-inputtext{
                border: 2px solid $accent;
                padding: 28px 40px;
                font-size: 16px;
                font-weight: 400;
                border-radius: 16px;
                max-width: 540px;
                width: 100%;
                background: $white;
                max-width: 100%;
                &.pin-input{
                    width: 85px;
                    padding: 15px;
                    border-radius: 5px;
                    position: absolute;
                    right: 22px;
                    border: 1px solid #f5f5f5;
                    top: 13px;
                    text-align: center;
                    background: $white;
                    &:focus{
                        box-shadow: none;
                        border: 1px solid #efe2ff;
                    }
                }
            }
            .device-label{
                background: $white;
                padding: 10px 15px;
                text-align: center;
                color: $accent;
                font-size: 18px;
                font-weight: 400;
                position: relative;
                letter-spacing: -1.04px;
                z-index: 1;
                border-radius: 5px;
                left: 30px;
                top: 10px;
                &.pin-label{
                    position: absolute;
                    left: auto;
                    right: 35px;
                    top: 0px;
                }
            }
        }
        .form-block{
            width: 100%;
            max-width: 600px;
            margin: auto;
            padding: 55px 75px;
            background: $white;
            border-radius: 22px;
            box-shadow: 0px 0px 75px 0px rgba(52, 52, 52, 0.25);
            .p-inputtext{
                display: flex;
                padding: 16px 0px;
                border: none;
                border-bottom: 1px solid rgba(18, 18, 29, 0.10);
                font-size: 20px;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: -1.044px;
                padding-left: 50px;
                border-radius: 0px;
                background-color: $white;
                &.p-password-input{
                    width: 100%;
                }
                &::placeholder{
                    color: $black-30;
                }
            }
            .p-icon-field{
                width: 100%;
            }
            .icon{
                position: absolute;
                top: 24px;
            }
            .info-msg{
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                color: $black-30;
            }
            .login-button{
                @extend .button;
                margin-top: 1.5rem;
            }
            .social-button{
                @extend .button;
                margin-top: 1.2rem;
                background: $white;
                box-shadow: 0px 2.293px 3.439px 0px rgba(0, 0, 0, 0.16);
                padding: 8px 45px;
                flex-direction: row-reverse;
                display: flex;
                align-items: center;
                justify-content: center;
                .p-button-label{
                    color: $black;
                    font-weight: 600;
                    flex: none;
                }
                .p-image{
                    line-height: 0px;
                }
                &:hover{
                    background: $black;
                    .p-button-label{
                        color: $white;
                    }
                    .apple{
                        -webkit-filter: grayscale(1) invert(1);
                        filter: grayscale(1) invert(1);
                    }
                }
            }
            .or-span{
                color: $black-30;
                font-size: 18px;
                font-weight: 400;
                text-align: center;
                margin: 1rem 0rem 0rem;
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0px;
                    width: 42%;
                    height: 1px;
                    background: $black-30;
                    top: 10px;
                }
                &::after{
                    @extend ::before;
                    right: 0px;
                    left: auto;
                }
            }
            a{
                color: #0E0020;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: -0.8px;
                text-decoration-line: underline;
                &:hover{
                    color: $primary;
                }
            }
            &.verify-email-block{
                h2{
                    margin: 0px;
                    color: #12121D;
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 98%;
                    letter-spacing: -1.6px;
                }
                p{
                    color: #0E0020;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 120%;
                    letter-spacing: -0.7px;
                    padding: 0rem 5rem;
                }
                .otp-input{
                    width: 100%;
                    max-width: 360px;
                    margin: 0 auto;
                    border: 1px solid rgba(18, 18, 29, 0.1);
                    border-radius: 24px;
                    text-align: center;
                    padding: 10px 0px;
                    font-size: 40px;
                    font-weight: 400;
                    line-height: 31.314px; /* 65.238% */
                    letter-spacing: 4.8px;
                }
            }
        }
        .err-msg{
            margin-top: 0.2rem;
        }
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
};

// Responsive CSS

@media (max-width:960px){
    .auth-wrapper{
        padding-bottom: 2rem;
        background-size: cover;
        .form-wrapper{
            height: 100%;
            .left-block{
                margin-bottom: 1.5rem;
                h1{
                    font-size: 38px;
                    line-height: 115%;
                }
                h2{
                    font-size: 28px;
                }
                .device-label{
                    padding: 20px 25px;
                    font-size: 18px;
                }
                .p-inputtext{
                    padding: 20px 0px 20px 25px;
                }
            }
            .form-block{
                padding: 20px;
                max-width: 100%;
                &.verify-email-block{
                    p{
                        padding: 0 2rem;
                    }
                }
            }
        }
    }
}

@media (min-width:1200px) and (max-width:1600px){
    .auth-wrapper .form-wrapper{
        height: auto;
        min-height: 525px;
        &.screen-height{
            height: calc(100vh -  225px);
        }
        .left-block{
            .device-label{
                padding: 15px 15px;
                font-size: 18px;
                left: 25px;
                &.pin-label{
                    right: 35px;
                    top: -4px;
                }
            }
            .p-inputtext{
                padding: 25px 0px 25px 25px;
                max-width: 100%;
                &.pin-input{
                    right: 20px;
                    top: 12px;
                }
            }
            h1{
                font-size: 54px;
            }
        }
        .form-block{
            padding: 30px 35px;
            max-width: 530px;
            .p-inputtext{
                padding: 15px 0px;
                padding-left: 45px;
                font-size: 18px;
            }
            .or-span{
                margin: 1rem 0rem 0rem;
            }
        }
    }
}

@media (min-width:963px) and (max-width:1199px){
    .auth-wrapper{
        background-size: cover;
        .form-wrapper{
            .left-block{
                h1{
                    font-size: 45px;
                }
                h2{
                    font-size: 30px;
                    letter-spacing: -2px;
                }
                .device-label{
                    padding: 10px 15px;
                    font-size: 16px;
                }
                .p-inputtext{
                    padding: 24px 25px;
                    max-width: 98%;
                    &.pin-input{
                        right: 25px;
                        top: 15px;
                        padding: 10px 15px;
                    }
                }
            }
            .form-block{
                padding: 30px 30px;
                max-width: 480px;
            }
            &.screen-height{
                height: calc(100vh - 245px);
                min-height: 530px;
            }
        }
    }
}

@media (max-width:767px){
    .auth-wrapper{
        padding-bottom: 1rem;
        background: url('../../../assets/images/bg-pattern-1.png') 85% no-repeat;
        background-size: cover;
        .form-wrapper{
            margin-top: 1rem;
            margin-bottom: 15rem;
            .left-block{
                h1{
                    text-align: center;
                    font-size: 30px;
                    letter-spacing: -1.6px;
                }
                .device-label{
                    padding: 8px 15px;
                    font-size: 14px;
                    left: 25px;
                    &.pin-label{
                        right: 20px;
                        top: 4px;
                    }
                }
                .p-inputtext{
                    padding: 20px 20px;
                    border-radius: 10px;
                    border: 1px solid #501D95;
                    &.pin-input{
                        right: 20px;
                        top: 14px;
                        padding: 8px 5px;
                        width: 60px;
                    }
                }
                .invalid-class.p-inputtext{
                    border: 1px solid #F73859;
                }
            }
            &.screen-height{
                min-height: auto;
                margin-top: 3.5rem;
                .left-block{
                    h1{
                        text-align: left;
                    }
                    h2{
                        letter-spacing: -1.5px;
                        font-size: 24px;
                    }
                }
            }
        }
    }
    footer{
        text-align: center;
        margin-top: 2.5rem;
        position: fixed;
        bottom: 0px;
        width: 100%;
        padding: 0.5rem 1rem 0.5rem;
        background: $white;
        z-index: 99;
        p, a{
            font-size: 13px;
        }
        
    }
    .button{
        .p-button-label{
            font-size: 15px;
        }
    }
    .support-badge{
        background: $secondary-bg;
        border-radius:0px 6px 6px 0px;
        display: inline-block;
        color: $white;
        position: fixed;
        right: 0;
        top: 76%;
        transform: translateY(-50%) rotate(180deg); /* Rotates the badge */
        z-index: 2;
        writing-mode: vertical-lr;
        padding: 0.7rem 0.4rem;
    
        .image-support {
            transform: rotate(90deg); /* Rotates the image by 90 degrees */
        }
        a{
            color: $white;
        }
        .label{
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: -0.6px;
        }
        .p-image{
            line-height: 0px;
            img{
                width: 20px;
            }
        }
        &:hover{
            background: $primary;
            a{
                color: $white;
            }
        }
    }
    .terms-label{
        color: $primary;
        display: block;
    }
}

@media (min-width:768px) and (max-width:963px){
    .auth-wrapper .form-wrapper{
        width: 95%;
        .left-block{
            h1{
                font-size: 32px;
                letter-spacing: -1.6px;
            }
            .device-label{
                padding: 8px 12px;
                font-size: 14px;
                &.pin-label{
                    right: 38px;
                    top: 4px;
                }
            }
            .p-inputtext{
                padding: 20px 20px;
                max-width: 96%;
                &.pin-input{
                    right: 30px;
                    top: 13px;
                    padding: 8px 5px;
                    width: 65px;
                }
            }
        }
    }
}