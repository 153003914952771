@import '../../assets/scss/variables';

.page-404-section{
    position: relative;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    background: $white url('../../assets//images/bg-pattern.png') 85% no-repeat;
    background-size: contain;
    .container{
        width: 90%;
        max-width: 1565px;
        margin: 0 auto;
        height: 100%;
        min-height: 100dvh;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $secondary-bg;
        h1{
            font-size: 64px;
            font-weight: 600;
            line-height: 107%;
            letter-spacing: -3.2px;
            margin: 0px;
        }
        .error-span{
            color: $accent;
            font-size: 24px;
            font-weight: 600;
            line-height: 29.325px;
            margin-bottom: 1rem;
            display: block;
        }
        p{
            font-size: 40px;
            font-weight: 400;
            line-height: 106%;
            letter-spacing: -3.2px;
            margin: 2rem 0rem 0rem;
        }
        .goto-button{
            background: $primary;
            border-radius:8px;
            padding: 14px 30px;
            border: none;
            margin-top: 3.625rem;
            max-width: 325px;
            .p-button-label{
                font-size: 23px;
                font-weight: 600;
                position: relative;
                z-index: 2;
                line-height: 36px;
                color: $white;
            }
            &:hover{
               background: $accent;
            }
        }
        .error-image{
            img{
                width: 100%;
                max-width: 580px;
            }
        }
    }
}

@media (max-width:991px){
    .page-404-section{
        .container{
            text-align: center;
            h1{
                font-size: 40px;
                letter-spacing: -1.8px;
            }
            p{
                font-size: 20px;
                line-height: 33px;
                margin-right: 0rem;
            }
            .goto-button{
                margin-top: 1.5rem;
            }
        }
    }
}

@media (min-width:991px) and (max-width:1199px){
    .page-404-section{
        .container{
            h1{
                font-size: 50px;
                letter-spacing: -1.8px;
            }
            p{
                font-size: 22px;
                line-height: 33px;
                margin-right: 0rem;
            }
            .goto-button{
                margin-top: 1.5rem;
            }
        }
    }
}