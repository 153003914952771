@import '../../assets/scss/variables';

.plans-block{
    padding-bottom: 2rem;
}
.content-wrapper{
    width: 90%;
    max-width: 1680px;
    margin: 0 auto;
    text-align: center;
    h1{
        color: $purple-dark;
        font-size: 64px;
        font-weight: 600;
        line-height: 150%;
        letter-spacing: -3.2px;
        margin: 0px;
    }
    .bundles-list-section{
        margin-top: 4.2rem;
        position: relative;
    }
}
.bundle-box{
    border-radius: 32px;
    background: #6B3EA5;
    padding: 3rem 2.5rem 2.5rem;
    color: $white;
    background-image: url('../../assets/images/bundle-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow: hidden;
    max-width: 385px;
    margin: 0 auto;
    transition: transform .2s;
    text-align: center;
    .flag-icon{
        line-height: 0px;
        font-size: 50px;
    }
    .icon{
        line-height: 0px;
    }
    h2{
        font-size: 30px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -1.6px;
        border-bottom: 2px solid $white;
        margin: 0px;
        position: relative;
        z-index: 1;
        display: inline;
        padding-bottom: 1px;
    }
    h3{
        font-size: 72px;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: -3.6px;
        margin: 3rem 0rem 10px;
        position: relative;
        z-index: 1;
    }
    p{
        color: #CBA3FF;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -1px;
        width: 90%;
        max-width: 190px;
        margin: 0 auto;
    }
    .buy-button{
        background: #461782;
        border-radius: 12px;
        padding: 10px 50px;
        border: none;
        transition: transform .2s;
        margin-top: 1.7rem;
        .p-button-label{
            font-size: 20px;
            font-weight: 600;
            position: relative;
            z-index: 0;
            line-height: 38px;
            letter-spacing: -1.195px;
        }
        &:hover{
            background: $primary;
            transform: scale(1.03);
        }
    }
    &::before{
        content: '';
        width: 100px;
        height: 100px;
        background: $accent;
        position: absolute;
        border-radius: 100%;
        left: 0rem;
        bottom: 0rem;
        z-index:0;
        filter: blur(50px);
        -webkit-filter: blur(50px);
        opacity: 0.75;
    }
    &::after{
        content: '';
        width: 200px;
        height: 200px;
        background: $accent;
        position: absolute;
        border-radius: 100%;
        right: 1rem;
        top: -6rem;
        z-index:0;
        filter: blur(50px);
        -webkit-filter: blur(50px);
        opacity: 0.5;
    }
    .popular-tag{
        border-radius: 0px 0px 15px 15px;
        background: #F2C215;
        color: $purple-dark;
        position: absolute;
        top: 0px;
        width: 100%;
        max-width: 125px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        height: 28px;
        margin: 0 auto;
        left: 0px;
        right: 0px;
        z-index: 9;
        span{
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
        }
        .pi{
            font-size: 14px;
        }
    }
    .select-info{
        color: $white;
        font-size: 28px;
        font-weight: 600;
        line-height:  125%;
        letter-spacing: -1.434px;
        border-bottom: 2px solid;
    }
    &:hover{
        transform: scale(1.05);
    }
}
.amount-save{
    color: #461782;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: -1px;
}
.plan-des{
    color: #838383;
    font-size: 24px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -1.2px;
    width: 98%;
    max-width: 1140px;
}
.select-data{
    color: #160033;
    font-size: 32px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -1.6px;
}
.select-plan-dropdown{
    border-radius: 8px;
    border: 2px solid #000;
    .p-dropdown-label{
        color: #160033;
        font-size: 28px;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -1.6px;
        padding: 0.4rem 0.75rem 0.4rem 1.2rem;
        min-width: 130px;
        text-align: left;
    }
    svg{
        color: #160033;
    }
}
.data-dropdown-panel{
    border-radius: 10px;
    border: 1px solid rgba(211, 221, 233, 0.18);
    padding: 4px 8px;
    .p-dropdown-items{
        .p-dropdown-item{
            background: #fff;
            border: 1px solid #e4d0ff !important;
            margin: 5px 0px;
            padding: 0.5rem 1rem;
            border-radius: 8px;
            .p-dropdown-item-label{
                font-size: 20px;
                font-weight: 500;
                line-height: 120%;
                letter-spacing: -1.6px;
            }
            &.p-highlight{
                border: 1px solid #e4d0ff !important;
                background: #f1e6ff !important;
            }
            &:hover{
                border: 1px solid #e4d0ff !important;
                background: #f1e6ff !important;
            }
        }
    }
}

@media (min-width:1200px) and (max-width:1750px){
    .bundle-box{
        padding: 3rem 1rem 2rem;
        h2{
            font-size: 23px;
        }
        h3{
            font-size: 58px;
            margin: 2rem 0rem 10px;
        }
        P{
            font-size: 18px;
            line-height: 110%;
        }
        .select-info{
            font-size: 20px;
            letter-spacing: -1px;
        }
    }
    .plans-footer{
        padding: 0.5rem 0rem 1rem !important;
    }
    .content-wrapper{
        width: 95%;
    }
}

@media (max-width:962px){
    .content-wrapper{
        margin-top: 2rem;
        h1{
            text-align: center;
            font-size: 32px;
            font-weight: 600;
            line-height: 116%;
            letter-spacing: -1.6px;
            padding: 0rem 1rem;
        }
       .bundles-list-section {
            margin-top: 2rem;
            padding-bottom: 3rem;
        }
    }
    .bundle-box{
        max-width: 350px;
        margin-bottom: 1rem;
        h2{
            font-size: 25px;
        }
        h3{
            font-size: 56px;
            margin: 2rem 0rem 10px;
        }
        p{
            font-size: 16px;
        }
    }
    .mobile-flex{
        flex-direction: column-reverse;
    }
    .plans-footer{
        padding-top: 4px !important;
    }
}
@media (min-width:964px) and (max-width:1199px){
    .content-wrapper{
        h1{
            font-size: 36px;
            letter-spacing: -2px;
        }
        .bundles-list-section{
            .list-container{
                .grid{
                    flex-wrap: wrap;
                    .col-12{
                        flex: 1 1 45%;
                    }
                }
            }
        }
    }
    .bundle-box {
        p{
            max-width: 205px;
            font-size: 16px;
        }
        h2 {
            font-size: 26px;
        }
        .bundle-box h3 {
            font-size: 56px;
        }
    }
}

@media (max-width:650px){
    .content-wrapper{
        .m-flex{
            flex-direction: column;
        }
    }
    .plan-des{
        font-size: 20px;
    }
    .select-data{
        font-size: 28px;
    }
    .select-plan-dropdown{
        .p-dropdown-label{
            font-size: 28px;
        }
    }
}